import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../components/Layout';
import OrderForm from '../components/OrderForm';

const OrderRealPropertyReport = () => (
  <Layout
    title="Free RPR, Real Property Report, quotes for for selling your home."
    description="RPR quotes for free, submit this form and we will get back to you within hours with all the information you need to sell your home."
    keywords=""
  >
    <main>
      <h1>Request a Quote for a Real Property Report</h1>

      <p>
        Need to order a Real Property Report? No problem. Just let us know who you are and some info
        about the property you need the report for, and we'll get in touch - expect us to contact
        you within one business day. Learn more about{' '}
        <Link to="/services/residential-surveys/real-property-reports">Real Property Reports</Link>.
      </p>

      <OrderForm type="rpr" />
    </main>
  </Layout>
);

export default OrderRealPropertyReport;
